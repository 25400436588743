import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './components/Pages/Home/Home';
import CoursesPage from './components/Pages/Courses/Courses';
import AboutPage from './components/Pages/About/About';
import ContactPage from './components/Pages/Contact/Contact';
import LoginPage from './components/Pages/Login/Login';
import VippsRedirect from './components/Vipps/VippsRedirect';
import { useEffect, useState } from 'react';
import { hasToken } from './functions/auth';
import { LoggedInUserContext, ModalOpenContext } from './context';
import TermsPage from './components/Pages/Terms/TermsAndConditions';
import { getUserFromToken } from './functions/fetch';
import { Payment } from './components/Pages/Payment/Payment';
import { ProfilePage } from './components/Pages/Profile/Profile';
import { AdminUsersPage } from './components/Pages/Admin/Users/Users';

const App = () => {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!hasToken()) {
      setLoggedInUser(null);
      return;
    }

    const fetchUser = async () => {
      const user = await getUserFromToken();
      setLoggedInUser(user);

      const url = sessionStorage.getItem('urlBeforeLogin');
      if (url) {
        sessionStorage.removeItem('urlBeforeLogin');
        return window.location.replace(url);
      }
    }
    fetchUser();
  }, [location]);

  return (
    <>
      <LoggedInUserContext.Provider value={{ loggedInUser, setLoggedInUser }}>
        <ModalOpenContext.Provider value={{ modalIsOpen, setModalIsOpen }}>
          <Navbar />
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/kurs/*' element={<CoursesPage />} />
            <Route path='/om-oss' element={<AboutPage />} />
            <Route path='/kontakt' element={<ContactPage />} />
            <Route path='/regler-og-betingelser' element={<TermsPage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/profil/*' element={<ProfilePage />} />
            <Route path='/admin/brukere' element={<AdminUsersPage />} />
            <Route path='/vipps/login-redirect' element={<VippsRedirect />} />
            <Route path='/vipps/payment/:paymentId' element={<Payment />} />
          </Routes>
          <Footer />
        </ModalOpenContext.Provider>
      </LoggedInUserContext.Provider>
    </>
  );
}

export default App;
