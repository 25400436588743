import './coursesGrid.css';
import { payment } from '../../../functions/vipps';
import { LoggedInUserContext } from '../../../context';
import { useContext, useEffect, useState } from 'react';
import { LoginModal } from '../../Modal/Login/LoginModal';
import { SelectChildModal } from '../../Modal/SelectChild/SelectChildModal';
import { addCourse, deleteCourse, editCourse, fetchProfilesFromChildrenIds } from '../../../functions/fetch';
import { SimpleSpinner } from '../../Spinner/SimpleSpinner';
import { ErrorModal } from '../../Modal/ErrorModal/ErrorModal';
import { ParticipantsListModal } from '../../Modal/ParticipantsList/ParticipantsList';
import { CourseGridRow } from './CoursesGridRow';
import { CourseModal } from '../../Modal/Course/CourseModal';

export const CourseGrid = ({ children, options }) => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectChildModalIsOpen, setSelectChildModalIsOpen] = useState(false);
  const [orderCourseId, setOrderCourseId] = useState(null);
  const [courses, setCourses] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [participantsListModalIsOpen, setParticipantsListModalIsOpen] = useState(false);
  const [courseListCourse, setCourseListCourse] = useState(null);
  const [editCourseModalIsOpen, setEditCourseModalIsOpen] = useState(false);
  const [editCourseModalData, setEditCourseModalData] = useState(null);


  useEffect(() => {
    setCourses(children);
  }, [children]);

  const preOrderChecks = async (id) => {
    // if (loggedInUser && loggedInUser.roles.includes('admin')) {
      if (!loggedInUser) {
        setModalIsOpen(true);
        return;
      }
      setSelectChildModalIsOpen(true);
      setOrderCourseId(id);
    // } else {
    //   setNewWebsiteModalIsOpen(true);
    //   return;
    // }
  }

  const orderFn = async (childId) => {
    if (!loggedInUser) return;
    if (!childId) {
      setError('Du må velge et barn for å kunne bestille kurset');
      return;
    }
    setLoading(true);
    try {
      const res = await payment(loggedInUser._id, childId, orderCourseId);
      if (res.error) {
        setError(res.error.message);
        // setErrorModalIsOpen(true);
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      // setErrorModalIsOpen(true);
      setLoading(false);
    }
  }

  const saveEditedCourse = async (editedCourse) => {
    const data =  editedCourse._id ? await editCourse(editedCourse) : await addCourse(editedCourse);
    // const data = await editCourse(editedCourse);
    if (data) {
      setCourses(courses.map(c => c._id === editedCourse._id ? editedCourse : c));
      console.log('Updated course!');
    }
    else console.log('Error saving course');
  }

  const saveNewCourse = async (newCourse) => {
    const data = await addCourse(newCourse);
    if (data) {
      // setCourses([ ...courses, data ]);
      console.log('Added course!');
      // setEditMode(false);
    }
    else console.log('Error saving course');
  }

  const removeCourse = async (id) => {
    const data = await deleteCourse(id);
    if (data) {
      setCourses(courses.filter(c => c._id !== id));
      setEditCourseModalIsOpen(false);
      console.log('Deleted course!');
    }
    else console.log('Error deleting course');
  }

  const openParticipantsList = async (course) => {
    const participants = course.participants.map(p => p._id);
    setParticipantsListModalIsOpen(true);
    const populatedParticipantsProfile = await fetchProfilesFromChildrenIds(participants);
    if (populatedParticipantsProfile) {
      setCourseListCourse({
        ...course,
        populatedParticipants: populatedParticipantsProfile,
        place: { place: course.place, name: options.place.name }
      });
    }
    else console.log('Error getting participants list');
  }

  const openCourseModal = (course) => {
    setEditCourseModalIsOpen(true);
    setEditCourseModalData(course);
  }

  const clearError = () => setError(null);

  return courses && courses.length > 0 && (
    <div className="course-grid">
      <div className="course-grid-row course-grid-header">
        <div className='expand-btn'></div>
        <div className='age'>Alder</div>
        <div className='experience-level'>Nivå</div>
        <div className='time'>Tidspunkt</div>
        <div className='day'>Dag</div>
        <div className='dates'>Startdato</div>
        <div className='duration'>Varighet</div>
        <div className='price'>Pris</div>
        <div className='available-spots'>Ledige plasser</div>
        <div className='order-btn'></div>
      </div>
      {/* {loggedInUser && loggedInUser.roles.includes('admin') && <tr><td className='pointer' colSpan={10} style={{ textAlign: 'center' }} onClick={options.newCourse}>Klikk her for å legge til et nytt kurs</td></tr>} */}
      {courses.map((course, i) => <CourseGridRow key={i} course={course} options={{ preOrderChecks, orderFn, removeCourse, saveEditedCourse, saveNewCourse, openParticipantsList, openCourseModal, instructors: options.instructors }} />)}
      
      {loggedInUser && loggedInUser.roles.includes('admin') && <div className="course-grid-wrapper">
        <div className="course-grid-row add-row">
          <div className="pointer" onClick={options.newCourse}>Klikk her for å legge til nytt kurs</div>
        </div>
      </div>}
      {loggedInUser && <SelectChildModal options={{ isOpen: selectChildModalIsOpen, close: () => setSelectChildModalIsOpen(false), onConfirm: orderFn, errorMessage: error, clearError }} />}
      <LoginModal options={{ isOpen: modalIsOpen, close: () => setModalIsOpen(false) }} />
      <ErrorModal options={{ isOpen: errorModalIsOpen, close: () => setErrorModalIsOpen(false) }}><p>{error}</p></ErrorModal>
      <ParticipantsListModal options={{ isOpen: participantsListModalIsOpen, close: () => { setParticipantsListModalIsOpen(false); setCourseListCourse(null); }, courseListCourse }} />
      {editCourseModalIsOpen && <CourseModal
        options={{
          isOpen: editCourseModalIsOpen,
          close: () => setEditCourseModalIsOpen(false),
          save: saveEditedCourse,
          deleteCourse: removeCourse,
          course: editCourseModalData,
          instructors: options.instructors
        }}
      />}
      {loading && <div className="loading-overlay"><SimpleSpinner /></div>}
    </div>
  );
}
