import { NavLink, useLocation } from 'react-router-dom';
// import './navbar.css';
import { useContext, useState } from 'react';
import { LoggedInUserContext } from '../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

export const AdminProfileNav = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const path = useLocation().pathname;

  const links = [
    { text: 'Brukere', path: '/admin/brukere' },
    // { text: 'Ordre', path: '' }
  ];

  if (!loggedInUser || !loggedInUser.roles.includes('admin')) return null;

  return (
    <nav>
      <div className="navbar admin">
        <div className="row">
          <div className="link-list">
            <ul>
              {links.map(link => <li key={link.text}><NavLink to={link.path} className={link.path.length === 0 ? 'disabled' : ''}>{link.text}</NavLink></li>)}  
            </ul>
          </div>
          <div className="link-list-mobile-open-button pointer">
            <FontAwesomeIcon icon={mobileNavOpen ? faXmark : faBars} onClick={() => setMobileNavOpen(!mobileNavOpen)} />
          </div>
        </div>
        {mobileNavOpen && <div className="link-list-mobile">
          <ul>
            {links.map(link => <li onClick={() => setMobileNavOpen(false)} key={link.text}><NavLink to={link.path}>{link.text}</NavLink></li>)}
          </ul>
        </div>}
      </div>
    </nav>
  );
}
