import './userSearch.css';

import { DebounceInput } from "../DebounceInput/DebounceInput";
import { useContext, useEffect, useState } from 'react';
import { searchUsers } from '../../../functions/fetch/users';
import PersonCard from '../../Cards/PersonCard';
import { Link } from 'react-router-dom';
import { LoggedInUserContext } from '../../../context';

export const UserSearch = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorText, setErrorText] = useState(null);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (!searchTerm) return;
    const searchForUsers = async () => {
      setFetching(true);
      try {
        const users = await searchUsers(searchTerm);
        setUsers(users);
        setFetching(false);
      } catch (error) {
        setUsers([]);
        if (error.status === 404) setErrorText('Fant ingen brukere');
        else setErrorText('Noe gikk galt');
        setFetching(false);
      }
    }
    searchForUsers();
  }, [searchTerm]);

  if (!loggedInUser || !loggedInUser.roles.includes('admin')) return null;

  const elId = 'user-search';

  return (
    <div className="user-search">
      <label htmlFor={elId}>Søk etter brukere</label>
      <DebounceInput options={{ id: elId, placeholder: 'Søk etter brukere', onChange: setSearchTerm }} />
      <div className="search-results">
        {!fetching && searchTerm.length > 0 && users.length === 0 && <p className="no-results">{errorText}</p>}
        {users && users.length > 0 && users.map(user => <Link to={`/profil/${user._id}`} key={user._id}>
          <PersonCard key={user._id} user={user}>
            <h4>{user.firstName} {user.lastName}</h4>
            <p>{user.roles.map(role => {
              if (role === 'admin') return 'Administator';
              if (role === 'instructor') return 'Instruktør';
              if (role === 'parent') return 'Forelder';
              if (role === 'child') return 'Barn';
              return 'Ukjent';
            }).join(', ')}</p>
          </PersonCard>
        </Link>)}
      </div>
    </div>
  );
}
