import { useContext, useEffect, useState } from 'react';
import { CustomSelect } from '../../Select/CustomSelect';
import TitleWithSeparator from '../../Separator/TitleWithSeparator';
import './courses.css';
import { useNavigate } from 'react-router-dom';
import { LoggedInUserContext } from '../../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons'
import { PlaceModal } from '../../Modal/Course/placeModal';
import { addPlace, deletePlace, editPlace, fetchCoursesForPlace, fetchPlaces, getInstructors } from '../../../functions/fetch';
import { CourseGrid } from '../../Courses/CoursesGrid/CoursesGrid';
import { SimpleSpinner } from '../../Spinner/SimpleSpinner';
import { BigPlaceCard } from '../../Cards/BigPlaceCard';

const CoursesPage = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [coursePlaces, setCoursePlaces] = useState(null);
  const [courses, setCourses] = useState(null);
  const [editPlaceModalIsOpen, setEditPlaceModalIsOpen] = useState(false);
  const [newPlaceModalIsOpen, setNewPlaceModalIsOpen] = useState(false);
  const [coursesLoading, setCoursesLoading] = useState(false);
  const [instructors, setInstructors] = useState([]);
  // const [placesLoading, setPlacesLoading] = useState(false);
  // const [urlCourseId, setUrlCourseId] = useState('');

  const navigate = useNavigate();

  // useEffect(() => {
  //   const pathname = window.location.pathname;
  //   const urlCourseId = pathname.substring(pathname.lastIndexOf('/') + 1);
  //   console.log("🚀 ~ useEffect ~ urlCourseId:", urlCourseId, urlCourseId.length)
  //   setUrlCourseId(urlCourseId);
  //   getCoursesForPlace(urlCourseId);
  // }, [window.location.pathname]);

  useEffect(() => {
    if (selectedPlace && selectedPlace._id) getCoursesForPlace(selectedPlace._id);
  }, [selectedPlace]);

  useEffect(() => {
    if (!selectedPlace) document.title = 'Bestill Kurs - Badenymfene babysvømming';
    else document.title = selectedPlace.name + ' - Badenymfene babysvømming';
  }, [selectedPlace]);

  useEffect(() => {

    const getPlaces = async () => {
      if (coursePlaces !== null) return;
      try {
        const data = await fetchPlaces();
        if (!data || data.statusCode === 500 || data.length === 0) throw new Error('No places found');
        setCoursePlaces(data);
      } catch (error) {
        console.log('Failed to fetch places');
      }
    }

    getPlaces();
  }, [coursePlaces]);

  const setSelectOption = id => {
    const options = document.querySelectorAll('.custom-select-wrapper select option');
    if (!coursePlaces) return;
    options.forEach(option => {
      if (id === option.value) {
        const place = coursePlaces.find(p => p._id === option.value);
        setSelectedPlace(place);
        option.setAttribute('selected', 'true');
      } else option.removeAttribute('selected');
    });
  }

  useEffect(() => {
    if (coursePlaces === null) return;
    const pathname = window.location.pathname;
    const urlCourseId = pathname.substring(pathname.lastIndexOf('/') + 1);
    if (urlCourseId && urlCourseId.length === 24) {
      setSelectOption(urlCourseId);
    }
  }, [coursePlaces]);

  useEffect(() => {
    const fetchInstructors = async () => {
      const instructors = await getInstructors();
      setInstructors(instructors);
    }
    fetchInstructors();
  }, []);

  const getCoursesForPlace = async (placeId) => {
    setCoursesLoading(true);
    setCourses(null);
    try {
      const courses = await fetchCoursesForPlace(placeId);
      setCourses(courses);
    } catch (error) {
      console.log('Failed to fetch courses for place', error);
    } finally {
      setCoursesLoading(false);
    }
  }

  const selectOnChange = (target) => {
    const courseId = target.value;
    const path = `/kurs/${courseId}`;
    if (target.tagName === 'SELECT') {
      const place = coursePlaces.find(p => p._id === target.selectedOptions[0].value);
      setSelectedPlace(place);
    }
    // getCoursesForPlace(courseId);
    navigate(path);
  }

  const saveNewPlace = async (newPlace) => {
    try {
      const data = await addPlace(newPlace);
      if (data) setCoursePlaces([ ...coursePlaces, data ]);
      else console.log('Error saving place');
    } catch (error) {
      setCoursePlaces(null);
      console.log('Failed to save place', error);
    }
  }

  const saveEditedPlace = async (editedPlace) => {
    try {
      const data = await editPlace(editedPlace);
      if (data) {
        const editedPlacesArray = [ ...coursePlaces ];
        const index = editedPlacesArray.findIndex(p => p._id === editedPlace._id);
        editedPlacesArray[index] = data;
        setCoursePlaces(editedPlacesArray);
      }
      else console.log('Error saving place');
    } catch (error) {
      setCoursePlaces(null);
      console.log('Failed to save place', error);
    }
  }

  const removePlace = async (id) => {
    try {
      const data = await deletePlace(id);
      if (data) {
        setCoursePlaces(coursePlaces.filter(p => p._id !== id));
        setEditPlaceModalIsOpen(false);
        window.location.replace('/kurs');
        console.log('Deleted place!');
      }
      else console.log('Error deleting place');
    } catch (error) {
      setCoursePlaces(null);
      console.log('Failed to delete place', error);
    }
  }

  const newCourse = () => {
    setCourses([ ...courses, {
      // _id: '',
      dates: [],
      place: selectedPlace._id,
      participants: [],
      instructor: [],
      price: 0,
      published: false,
      weekday: 0,
      maximumParticipants: 0,
      experienceLevel: '',
      info: '',
      participantsAge: '',
      timeFrom: '',
      timeTo: ''
    }]);
  }

  return (
    <div className="content-margined">
      <div className='courses'>
        <TitleWithSeparator title="Bestill kurs" />
        {loggedInUser && loggedInUser.roles.includes('admin') &&
          <div className='admin-action-buttons'>
            {selectedPlace && <div onClick={() => setEditPlaceModalIsOpen(true)}>
              <FontAwesomeIcon
                icon={faPenToSquare}
                size='2x'
              />
              <p>Endre sted</p>
            </div>}
            <div onClick={() => setNewPlaceModalIsOpen(true)}>
              <FontAwesomeIcon
                icon={faPlus}
                size='2x'
              />
              <p>Nytt sted</p>
            </div>
          </div>
        }
        {selectedPlace && selectedPlace.info && <BigPlaceCard place={selectedPlace} />}
        <div className="custom-select-wrapper">
          <h3>Velg kurssted</h3>
            <CustomSelect
              defaultText='Velg kurssted'
              onChange={e => selectOnChange(e.target)}
            >
              <option value="0" disabled>Velg kurssted</option>
              {coursePlaces && coursePlaces.map(place => (
                <option value={place._id} key={place._id}>{place.name}</option>
              ))}
            </CustomSelect>
        </div>
        {/* <CoursesList>{courses}</CoursesList> */}
        {coursesLoading && <SimpleSpinner />}
        {!coursesLoading && selectedPlace && courses && <CourseGrid options={{ newCourse, place: selectedPlace, instructors }}>{courses}</CourseGrid>}
      </div>
      {editPlaceModalIsOpen && <PlaceModal
        options={{
          isOpen: editPlaceModalIsOpen,
          close: () => setEditPlaceModalIsOpen(false),
          save: e => saveEditedPlace(e),
          editPlace: true,
          deletePlace: removePlace,
          data: () => {
            if (!coursePlaces) return null;
            return selectedPlace;
          }
        }}
      />}
      {newPlaceModalIsOpen && <PlaceModal
        options={{
          isOpen: newPlaceModalIsOpen,
          close: () => setNewPlaceModalIsOpen(false),
          save: e => saveNewPlace(e)
        }}
      />}
    </div>
  );
}

export default CoursesPage;
