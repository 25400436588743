import './participantsList.css';
import Button from "../../Buttons/Button";
import { Modal } from "../Generic/Modal";

export const ParticipantsListModal = ({ options, children }) => {

  if (!options.courseListCourse) {
    return null;
  }
  
  const modalId = 'participants-list-modal';

  return (
    <Modal options={{ ...options, modalId: modalId, title: 'Kursliste' }}>
      <div className="participants-list">
        <div className="course-list-header">
          <div className="top">
            <h3 className='' style={{ margin: '0' }}>{options.courseListCourse.place.name}</h3>
            {/* <p>{options.courseListCourse.instructors.map(instructor => <span key={instructor._id}>{instructor.firstName} {instructor.lastName}</span>)}</p> */}
          </div>
          <div className="bottom">
            <h4 className='' style={{ margin: '0' }}>{options.courseListCourse.timeFrom} - {options.courseListCourse.timeTo} {options.courseListCourse.experienceLevel} {options.courseListCourse.participantsAge}</h4>
          </div>
        </div>
        {/* <hr /> */}
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'unset' }}>Barn</th>
              <th style={{ textAlign: 'unset' }}>Foresatt</th>
              {options.courseListCourse.dates.map(dateStr => {
                // TODO: Some dates are creating a new date object
                const date = new Date(dateStr);
                return (<th align='center' width="50px" key={date.getTime()}>{date.getDate()}.{date.getMonth() + 1}</th>)})}
            </tr>
          </thead>
          <tbody>
          {options.courseListCourse.populatedParticipants.map((profile, profileIndex) => profile.children.filter(child => options.courseListCourse.participants.map(p => p._id).includes(child._id)).map(child => (
            // {profile.children.filter(child => options.courseListCourse.participants.map(p => p._id).includes(child._id)).map(child => (
              <tr key={child._id} style={{ backgroundColor: profileIndex % 2 === 0 ? '#fff' : '#f5f5f5' }}>
                <td>{child.firstName} {child.lastName}<br />{new Date(child.birthdate).toLocaleDateString()}</td>
                <td>
                  {profile.parents.map((parent, parentIndex) => <span key={parent._id} style={{ display: 'block' }}>{parent.firstName} {parent.lastName} - <a href={`tel:${parent.phone.substring(2)}`}>{parent.phone.substring(2)}</a><br /><a className='parent-email' href={`mailto:${parent.email}`}>{parent.email}</a></span>)}
                </td>
                {options.courseListCourse.dates.map(date => <td
                  align='center'
                  width="50px"
                  key={`square-${new Date(date).getTime()}`}>
                    <div className='course-list-check-square'></div>
                  </td>)}
              </tr>
            )))}
          </tbody>
        </table>
      </div>
      <div className="actions">
        <Button onClickFn={options.close}>Lukk</Button>
        <Button onClickFn={() => {
          const printContent = document.querySelector(`#${modalId} .participants-list`).innerHTML;
          const w = window.open();
          const style = document.createElement('style');
          style.innerHTML = `@page {
  size: A4 landscape;
  margin: 0;
}
body {
  margin: 15px;
}
h3.course-list-header {
  font-size: 2em;
}
h4.course-list-header {
  font-size: 1.2em;
}
.course-list-header {
  margin: 0;
}
hr {
  margin: 5px 0;
  border: none;
  border-top-color: #d9d9d9;
  border-top-width: 2px;
  border-top-style: solid;
}
.profile .child p {
  margin: 0;
}
.course-list-check-square {
  width: 20px;
  height: 20px;
  border: 2px solid #d9d9d9;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-size: 14px;
  padding: 0 10px;
  border-top: 2px solid #d9d9d9;
  margin-top: 10px;
}
thead {
  border-bottom: 1px solid #d9d9d9;
}
tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}
tbody td a.parent-email {
  display: none;
}
tbody td a {
  text-decoration: none;
  color: #000;
}
thead th {
  font-weight: unset;
  padding: 10px 5px 5px 5px;
}
thead th:nth-child(n+3) {
  text-align: center;
}
th,
td {
  padding: 5px;
  margin: 0;
}`;
          w.document.write(printContent);
          w.document.head.appendChild(style);
          w.print();
          w.close();
          options.close();
        }}>Skriv ut..</Button>
      </div>
    </Modal>
  );
}
