import './courseHistoryTable.css';
import { useEffect, useState } from 'react';
// import config from '../../config';

export const CourseHistoryTable = ({ children, options }) => {
  const [courses, setCourses] = useState(null);
  const [instructors, setInstructors] = useState([]);
  
  // useEffect(() => {
  //   const fetchInstructors = async () => {
  //     const res = await fetch(`${config.api.baseUrl}/users/instructors`);
  //     const instructors = await res.json();
  //     setInstructors(instructors);
  //   }
  //   fetchInstructors();
  // }, []);


  return (
    <div className="course-history-table-wrapper">
      <table className="course-history-table">
        <thead>
          <tr>
            <th></th>
            <th>Barn</th>
            <th>Sted</th>
            <th>Alder</th>
            <th>Nivå</th>
            <th>Tidspunkt</th>
            <th>Dag</th>
            <th>Startdato</th>
            <th>Varighet</th>
            <th>Pris</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </table>
    </div>
  );
}
