import './profile.css';
import { useContext, useEffect, useState } from 'react';
import TitleWithSeparator from '../../Separator/TitleWithSeparator';
import { LoggedInUserContext } from '../../../context';
import { AdminProfileNav } from '../../Navbar/AdminProfileNav';
import PersonCard from '../../Cards/PersonCard';
import { addChild, deleteChild, fetchProfileFromParentId, updateChild } from '../../../functions/fetch';
import { NotLoggedIn } from '../../Errors/NotLoggedIn';
import { CourseHistoryTable } from '../../Courses/CourseHistoryTable/CourseHistoryTable';
import { weekdayHelper } from '../../../functions/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { NotAuthorized } from '../../Errors/NotAuthorized';
import { AddChildModal } from '../../Modal/AddChild/AddChildModal';

export const ProfilePage = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [populatedProfile, setPopulatedProfile] = useState(null);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);
  const [editingChild, setEditingChild] = useState(null);
  const params = useParams();

  document.title = 'Profil - Badenymfene Babysvømming';

  useEffect(() => {
    const fetchProfile = async () => {
      if (!loggedInUser) return;
      if ((params['*'].length > 0 && params['*'] !== loggedInUser._id) && !loggedInUser.roles.includes('admin')) {
        setNotAuthorized(true);
        return;
      };
      const profileId = params['*'] ? params['*'] : loggedInUser._id;
      try {
        const profiles = await fetchProfileFromParentId(profileId);
        setPopulatedProfile(profiles[0]);
      } catch (error) {
        window.location.reload();
      }
    }
    fetchProfile();
  }, [loggedInUser]);

  if (!loggedInUser) return <div className='content-margined'><NotLoggedIn /></div>;
  if (notAuthorized) return <div className='content-margined'><NotAuthorized /></div>;

  const openChildModal = (childId) => {
    if (childId) {
      setEditingChild(populatedProfile.children.find(c => c._id === childId));
    }
    setIsChildModalOpen(true);
  }

  const removeChild = async (childId) => {
    if (!window.confirm('Er du sikker på at du vil slette denne brukeren?')) return;
    const deletedChildRes = await deleteChild(childId);
    if (deletedChildRes) {
      setPopulatedProfile({ ...populatedProfile, children: populatedProfile.children.filter(c => c._id !== childId) });
      setIsChildModalOpen(false);
    }
  }

  const saveNewChild = async (newChild) => {
    const addedChildRes = await addChild(newChild);
    if (addedChildRes) {
      setPopulatedProfile({ ...populatedProfile, children: [...populatedProfile.children, addedChildRes] });
      setIsChildModalOpen(false)
    }
  }

  const editChild = async (child) => {
    const updatedChildRes = await updateChild(child);
    if (updatedChildRes) {
      setPopulatedProfile({ ...populatedProfile, children: populatedProfile.children.map(c => {
        if (c._id === child._id) {
          return { ...c, firstName: child.firstName, lastName: child.lastName, birthdate: child.birthdate };
        } else {
          return c;
        }})});
      setIsChildModalOpen(false)
    }
  }

  return (
    <div className="profile-page">
      {loggedInUser.roles.includes('admin') &&
        <AdminProfileNav />
      }
      <div className="content-margined">
        <TitleWithSeparator title="Min profil" />
        <div className="persons">
          <div className="parents">
            <h3>Foresatte</h3>
            <div className="person-cards">
              {populatedProfile && populatedProfile.parents.map((parent) => <PersonCard key={parent._id} user={parent}>
                <h4>{parent.firstName} {parent.lastName}</h4>
                <p>{parent.email}</p>
                <p>{parent.phone.substring(2)}</p>
              </PersonCard>)}
            </div>
            <p className='info-text'>Dersom du ønsker å endre opplysningene dine, må det gjøres hos Vipps.</p>
          </div>
          <div className="children">
            <h3>Barn</h3>
            <div className="person-cards">
              {populatedProfile && populatedProfile.children.map((child) => {
                const birthdate = new Date(child.birthdate);
                const today = new Date();
                const diffDate = new Date(today.getTime() - birthdate.getTime());
                const diffYears = diffDate.getUTCFullYear() - 1970;
                const diffMonths = diffDate.getUTCMonth();
                const diffDays = diffDate.getUTCDate();
                // const ageInMonths = diffMonths < 12 ? diffMonths : null;
                // const ageInDays = diffDays < today.getUTCDate() ? diffDays : null;
                let ageStr = '';
                if (diffYears) ageStr += `${diffYears} år`;
                if (diffMonths && diffYears < 2) ageStr += ` ${diffMonths} mnd`;
                // if (diffDays && diffYears < 2 && diffMonths < 12) ageStr += ` ${diffDays} dager`;
                if (diffDays && diffYears < 1 && (diffYears < 1 && diffMonths < 12)) ageStr += ` ${diffDays} dager`;

                return <PersonCard key={child._id} user={child}>
                <h4>{child.firstName} {child.lastName}</h4>
                <p>{ageStr} ({new Date(child.birthdate).toLocaleDateString()})</p>
                <div className='actions'>
                  <p onClick={() => openChildModal(child._id)}>Endre</p>
                </div>
              </PersonCard>})}
              <PersonCard user={null}>
                <div className='add-child' onClick={() => openChildModal()}>
                  <p>Legg til barn</p>
                  <FontAwesomeIcon icon={faPlus} size='2x' />
                </div>
              </PersonCard>
            </div>
          </div>
        </div>
        <div className="course-history">
          <h3>Kurshistorikk</h3>
          <CourseHistoryTable>
            {populatedProfile && populatedProfile.children.map((child) => {
              if (!child.coursesHistory) return;
              return child.coursesHistory.map((course) => {
                let status = 'Påmeldt';
                const now = Date.now();
                if (now >= new Date(course.dates[0]).getTime() && now <= new Date(course.dates[course.dates.length - 1]).getTime()) {
                  status = 'Pågår';
                } else if (now >= new Date(course.dates[course.dates.length - 1]).getTime()) {
                  status = 'Fullført';
                }
                return <tr key={course._id}>
                  <td></td>
                  <td>{child.firstName}</td>
                  <td>{course.place.name}</td>
                  <td>{course.participantsAge}</td>
                  <td>{course.experienceLevel}</td>
                  <td>{course.timeFrom} - {course.timeTo}</td>
                  <td>{weekdayHelper(new Date(course.dates[0]).getDay())}</td>
                  <td>{new Date(course.dates[0]).toLocaleDateString()}</td>
                  <td>{course.dates.length + 1}</td>
                  <td>{course.price}</td>
                  <td>{status}</td>
                </tr>
              })
            })}
          </CourseHistoryTable>
        </div>
      </div>
      <AddChildModal options={{
        isOpen: isChildModalOpen,
        close: () => {
          setIsChildModalOpen(false);
          setEditingChild(null);
        },
        deleteChild: removeChild,
        saveNewChild,
        updateChild: editChild,
        child: editingChild
      }} />
    </div>
  );
}

