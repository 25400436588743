import { useContext } from "react";
import { LoggedInUserContext } from "../../../context";

export const CourseGridRowExpanded = ({ course, options }) => {
  const { loggedInUser } = useContext(LoggedInUserContext);

  if (!course) return null;
  const availableSpots = !course.participants || course.participants.length >= course.maximumParticipants ? 0 : (course.maximumParticipants || 0) - course.participants.length;

  // const timeFromDate = new Date(`1970-01-01 ${course.timeFrom}`);
  // const timeToDate = new Date(`1970-01-01 ${course.timeTo}`);
  // const durationDate = new Date(timeToDate.getTime() - timeFromDate.getTime());

  return <div className="expanded">
    {/* {!loggedInUser &&  */}
    {loggedInUser && (loggedInUser.roles.includes('instructor') || loggedInUser.roles.includes('admin')) && 
      <div className='admin-info'>
        <div>
          {course.participants && course.participants.length > 0 &&
            <p className="pointer">
              <a href="#" onClick={e => {
                e.preventDefault();
                options.openParticipantsList(course);
              }}>Kursliste</a>
            </p>}
        </div>
        <div className="edit-course-btn">
          <p className="pointer">
            <a href="#" onClick={e => {
              e.preventDefault();
              options.openCourseModal(course);
            }}>Rediger kurs</a>
          </p>
        </div>
      </div>
    }
    <div className="grid-wrapper">
      {loggedInUser && (loggedInUser.roles.includes('instructor') || loggedInUser.roles.includes('admin')) && 
        <div className='participants'>
          <div>Påmeldte:</div>
          <div>
            {course.participants && course.participants.length > 0 ? course.participants.map((participant, pIndex) => 
              <p key={`participant-${pIndex}`} className='no-margin'>
                {typeof participant !== 'string' && `${participant.firstName} ${participant.lastName}`}
              </p>)
            :
              <p className='no-margin'>Ingen påmeldte</p>
            }
          </div>
        </div>
      }
      <div className='instructors'>
        <div>Instruktør:</div>
        <div>{options.instructorsForCourse && options.instructorsForCourse.length > 0 ? options.instructorsForCourse.map(instructor => (instructor) ? `${instructor.name}` : 'Foreløpig ukjent') : "Foreløpig ukjent"}</div>
      </div>
      <div className='experience-level'>
        <div>Nivå:</div>
        <div>{course.experienceLevel}</div>
      </div>
      <div className='time'>
        <div>Tidspunkt:</div>
        <div>{course.timeFrom} - {course.timeTo}</div>
      </div>
      <div className='dates'>
        <div>Datoer:</div>
        <div>{course.dates && course.dates.length && course.dates.map(date => (<div key={new Date(date).getTime()}>{new Date(date).toLocaleDateString()}</div>))}</div>
      </div>
      <div className='duration'>
        <div>Varighet:</div>
        <div>{course.dates && course.dates.length ? course.dates.length : 0} ganger</div>
      </div>
      <div className='price'>
        <div>Pris:</div>
        <div>{course.price} kr</div>
      </div>
      <div className='available-spots'>
        <div>Ledige plasser:</div>
        <div>{availableSpots}</div>
      </div>
      {course.info && <div className="information">
        <div>Informasjon:</div>
        <div className="pre-wrap">{course.info}</div>
      </div>}
    </div>
  </div>
}
